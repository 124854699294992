.signup-container {
  color: dimgray;
  font-weight: bold;
  margin: 16px 0;
}
.signup {
  margin: 0 6px;
  cursor: pointer;
  color: #1a9c90;
}
.signup:hover {
  color: #223f54;
}
.login-card {
  height: 60%;
  overflow: auto;
  width: 400px;
  place-content: space-between;
}

.login-button {
  width: 94%;
  margin: 14px 0;
  height: 36px;
}

.forgot-password {
  margin: 8px;
}

@media (max-width: 424px) {
  .login-card {
    width: 300px;
  }
}

@media (max-height: 680px) {
  .login-card {
    height: 90%;
  }
}
