.log-container {
  margin: 8px 0 0;
  background-color: #242424 !important;
  align-content: flex-start;
}
.appCode-stream-section {
  width: 44%;
  height: 100%;
}
.appCode-editor-section {
  width: 54%;
  height: 100%;
}
.super-code-editor-card {
  flex-grow: 1;
}
.super-code-editor-run-code {
  height: 40px;
  background: #282a36;
  padding: 0 0 0 49px;
}
@media (max-width: 1024px) {
  .background {
    flex-wrap: wrap-reverse;
  }
  .appCode-stream-section {
    width: 100%;
    height: calc(100% - 60px);
  }

  .appCode-editor-section {
    height: unset;
    width: 100%;
    .super-code-editor-card {
      flex-grow: unset;
    }
    #superCodeEditor {
      display: none;
    }
    .super-code-editor-run-code {
      background: rgb(243, 245, 247);
      padding: 0 0 0 10px;
      label {
        color: #223f53;
        font-weight: bold;
      }
    }
  }
}
