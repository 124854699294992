.save-container {
  display: grid;
  grid-auto-rows: auto auto;
  gap: 10px;
}
.model-download-container {
  position: relative;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-radius: 3rem;
  background: whitesmoke;
}
.model-download-button {
  position: absolute;
  top: 0;
  left: 0;
}
.image {
  object-fit: contain;
  border-radius: 1rem;
}
.colored-circle {
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  height: 38px;
  display: flex;
  align-self: center;
  cursor: pointer;
}
.grid-checkbox {
  font-size: 18px;
  margin: 6px 0;

  span:first-child {
    padding: 0;
    width: 16px;
    height: 16px;
    margin: 0 12px 0 0;
  }

  .rmd-label {
    cursor: pointer;
  }

  .rmd-label--disabled {
    cursor: not-allowed;
  }
}

.not-allowed {
  fill: #000000a8;
  cursor: not-allowed;
}
.mask {
  color: #6f6e6e !important;
  font-weight: 600;
  text-align: center;
}
.thumb-image-four {
  object-fit: contain;
  border-radius: 6px;
}
.grid-common {
  display: grid;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.grid-15 {
  @extend .grid-common;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.grid-30 {
  @extend .grid-common;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.grid-45 {
  @extend .grid-common;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.grid-border-child {
  border: 1px solid black;
}
.grid-child:hover {
  border: 3px solid black;
}
.skip-tutorial {
  text-align: end;
  cursor: pointer;
  color: #1a9c90;
  &:hover {
    color: #223f54;
  }
}

.card-content-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-marking-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.appGrid-capture-section {
  width: 30%;
}

.appGrid-data-section {
  width: 25%;
}
.appGrid-marking-section {
  width: 43%;
}

.appGrid-capture-section-button-container {
  height: fit-content;
  justify-content: space-between;
  margin: 10px 0 0;
}

.appGrid-capture-section-button {
  width: 49%;
}
