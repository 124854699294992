.appClass-class-section {
  width: 33%;
}

.appClass-sample-section {
  width: 33%;
}
.appClass-detail-section {
  width: 32%;
}
