//
// IDENTIFICATION-TAB
//

.qualityAnalysisSeparator {
  margin-top: 24px;
}

#rgbBinarizationWrapper {
  .input-range {
    width: calc(100% - 120px);
    display: flex;
    justify-content: center;
  }
}

.quality-binarization,
.quality-morphology {
  width: 48%;
}

.quality-identification-slider-container {
  padding: 0 22px;
}

.common-slider-title {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  color: #616161;
}

//
//  PREPROCESSING TAB
//

.quality-roi-grid {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 1fr 3fr 1fr;
  justify-content: center;
}

@media (max-width: 1024px) {
  .quality-binarization,
  .quality-morphology {
    width: 100%;
  }
}
