.post-processing-state {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(13, 173, 159, 0.2);
}

.post-processing-state-text-field {
  width: 80% !important;
  input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}

.post-processing-arrow {
  stroke: red;
  stroke-width: 3;
  cursor: pointer;
}

.config-buttons {
  border-radius: 0 !important;
  border: 1px solid dimgrey !important;
}

.separator {
  border: 1px solid dimgrey;
  height: 100%;
}

.config-box {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  width: 90%;
  border-radius: 0.25rem;
  padding: 8px;
  transition: border-color 150ms ease-in-out;
  &:hover {
    border-color: black;
  }
}

.first-config-box {
  margin: 12px 0;
}

.transition-title {
  margin: 0;
}

.post-processing-config-height {
  min-height: unset !important;
  height: 34px !important;
}

.selected-checkbox {
  span {
    span {
      color: #00a85f;
    }
  }
}

.boolean-config-checkbox {
  span {
    span {
      color: #223f54;
    }
  }
}
