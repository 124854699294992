.loading {
  $colors: #4aabb5, #00969c, #00796b, #004d54, #102921;
  display: flex;
  justify-content: center;
  animation-delay: 1s;
  text-align: justify;

  .dot {
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}

.training-button-disabled {
  background: #cccccc !important;
  color: #9e9e9e9e !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.error-input {
  border: 2px solid #b31100;
  color: #b31100;
}
