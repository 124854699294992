@import './themeVariables';

.full-page {
  background: #dadde2;
}

.bottom-bar {
  height: 48px;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  background: white;
}

.background {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px 10px;
}

.full-size {
  width: 100%;
  height: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-center-top {
  align-items: flex-start;
  justify-content: center;
}

.flex-center-bottom {
  align-items: flex-end;
  justify-content: center;
}

.flex-around {
  align-items: center;
  justify-content: space-around;
}

.flex-between {
  align-items: center;
  justify-content: space-between;
}

.flex-evenly {
  align-items: center;
  justify-content: space-evenly;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  margin: 0 auto;
}

.curve-border {
  border-radius: 3rem;
}

.curve-border-2 {
  border-radius: 8px;
}

.grown {
  flex-grow: 1;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.separator {
  height: 2px;
  background: $rmd-theme-primary;
  border: none;
  margin: 0 18px;
}

.grid-column-3 {
  display: grid;
  grid-template-columns: 30% 25% 30%;
  justify-content: space-evenly;
}

.text-center {
  text-align: center !important;
}

.pointer {
  cursor: pointer;
}

.fitted {
  width: fit-content;
  height: fit-content;
}

.title-card-text {
  text-align: left;
  font-weight: 600;
  font-size: 26px;
  padding: 10px 24px 0;
  height: 70px;
  color: #223f54;
}

.scroll {
  overflow: auto;
}

.text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.vertical-items-top {
  align-items: flex-start;
}

.none {
  display: none;
}

.circular-container {
  height: 42px;
  border-radius: 0.5rem;
  span {
    width: 24px;
    height: 24px;
  }
}

.conditional-wrapper {
  border: 1px solid;
  border-radius: 0.5rem;
  border-color: transparent #0000001f #0000001f #0000001f;
  &:hover {
    border-color: transparent #000000de #000000de #000000de;
  }
}

.full-wrapper {
  border: 1px solid #0000001f;
  border-radius: 0.5rem;
  &:hover {
    border-color: #000000de;
  }
}

.conditional-child {
  padding: 24px 12px 12px 12px;
}

@media (max-width: 1024px) {
  .hide-when-small {
    display: none;
  }
  .fill-when-small {
    flex-grow: 1;
  }
  #sideBar8000 {
    width: 50%;
  }
}
