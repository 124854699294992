@import './theme';

#groupsID {
  .rmd-circular-progress__circle {
    stroke: $rmd-theme-primary !important;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: 11px;
    scrollbar-height: 11px;
    scrollbar-color: #979797 #bbbec3;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  *::-webkit-scrollbar-track {
    background: #bbbec3;
    border-radius: 2px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #979797;
    border-radius: 2px;
  }
}

div:focus,
button:focus,
span:focus {
  outline: none !important;
}
.rmd-button {
  height: 42px;
  --rmd-states-focus-shadow: transparent;
}
.rmd-button--disabled {
  background: #cccccc !important;
  cursor: not-allowed !important;
}
.rmd-button--error {
  color: white !important;
  background: #b31100 !important;
}
.rmd-button--text {
  line-height: 1.2 !important;
  font-weight: bold !important;
  font-size: 0.785rem !important;
}

.Toastify__toast {
  font-weight: 600 !important;
}
.Toastify__toast--error {
  background: #b31100 !important;
}
.Toastify__toast--success {
  background: #009c39 !important;
}
.Toastify__toast--warning {
  background: #f66b00 !important;
}
.Toastify__toast--info {
  background: #004a5a !important;
}
.rmd-list {
  z-index: 30 !important;
}
.applyCamProgress {
  .rmd-circular-progress__circle {
    stroke: white !important;
  }
  .rmd-circular-progress__svg {
    height: 30px;
    width: 30px;
  }
}
.ace-dracula {
  background: #212121;
}

.input-range__slider {
  border: 1px solid #163e31 !important;
  background: $rmd-theme-secondary !important;
}
.input-range__track--active {
  background: $rmd-theme-secondary !important;
}

.input-range__label {
  color: #696969 !important;
}

#redBinarizationSlider {
  .input-range__slider {
    border: 1px solid #860000 !important;
    background: #a30000 !important;
  }
  .input-range__track--active {
    background: #a30000 !important;
  }
}
#greenBinarizationSlider {
  .input-range__slider {
    border: 1px solid #007d0c !important;
    background: #009e0e !important;
  }
  .input-range__track--active {
    background: #009e0e !important;
  }
}
#blueBinarizationSlider {
  .input-range__slider {
    border: 1px solid #00155f !important;
    background: #001b82 !important;
  }
  .input-range__track--active {
    background: #001b82 !important;
  }
}

.input-range--disabled .input-range__slider,
.input-range--disabled .input-range__track {
  background: #cccccc !important;
  cursor: not-allowed !important;
  border: #c1c1c1 !important;
}

.smcx-embed {
  height: 500px !important;
}
.rmd-app-bar {
  height: 60px !important;
}

.rmd-text-field-container--outline {
  height: 44px !important;
  .rmd-floating-label {
    top: -6px;
  }
  .rmd-floating-label--active {
    top: 0;
  }
}

.rmd-text-field-container--disabled {
  input {
    color: #9e9e9e !important;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

.rmd-textarea-container {
  height: auto !important;
}

.rmd-card,
.rmd-dialog {
  background-color: #f3f5f7 !important;
  .rmd-floating-label {
    background-color: #f3f5f7 !important;
  }
  .rmd-text-field-container--outline-active {
    label {
      background-color: #f3f5f7 !important;
    }
  }
}

#sideBar8000 {
  background-color: rgb(34, 63, 84) !important;
}

.rmd-dialog__title {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #223f54 !important;
}

.awssld__bullets {
  bottom: 0 !important;
  z-index: 2;
  button {
    background-color: #223f54 !important;
  }
}
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-left:before,
.awssld__controls__arrow-right:after,
.awssld__controls__arrow-right:before {
  background-color: #223f54 !important;
}

#camConfigCapabilitiesContainer,
#videoCamConfigContent,
#IPCamConfigContent,
#imagesCamConfigContent,
#systemCamConfigContent {
  .input-range {
    margin: 24px 0 36px 0;
    width: calc(100% - 80px);
  }
  .input-range__label--value {
    span {
      left: -50%;
    }
  }
}
