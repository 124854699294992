.cards-inner-container {
  place-content: space-evenly flex-start;
  height: fit-content;
  width: fit-content;
}
.inner-card {
  cursor: pointer;
  width: 280px;
  height: 280px;
  display: flex !important;
  flex-wrap: wrap;
  background-color: #dadde2 !important;
  align-content: flex-start;
  margin: 10px;
}
.inner-card-title {
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #223f54;
}
.inner-card-content {
  width: 100%;
  padding: 0 !important;
  height: calc(100% - 56px);
  div {
    padding: 0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px;
  }
}
.app-image-container {
  width: 100%;
  height: 100%;
}

.apps-container {
  width: 94%;
  height: 94%;
}
.apps-title {
  font-weight: 600;
  color: #223f54;
  font-size: 30px;
  margin: 6px 0;
}
@media (max-width: 767px) {
  .apps-container {
    width: 97%;
    height: 97%;
    border-radius: 10px !important;
  }
  .apps-title {
    font-size: 24px;
  }
  .cards-inner-container {
    place-content: flex-start;
    width: 100% !important;
  }
  .inner-card {
    width: 100%;
    flex-wrap: unset;
    height: 80px;
    align-items: center;
    flex-direction: row-reverse;
    min-width: unset !important;
    max-height: unset !important;
    margin: 4px 0;
  }
  .inner-card-title {
    width: calc(100% - 80px);
    font-size: 16px !important;
  }
  .app-image-container {
    width: 80px;
    height: 80px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .inner-card-content {
    width: 80px;
    height: 80px;
  }
}
