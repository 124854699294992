@import '../../../../../themeVariables';

.person-name {
  padding: 0 74px;
  font-weight: 600;
  color: slategray;
  font-size: 19px;
}

.image-gallery-thumbnails-wrapper {
  width: calc(100% - 150px);
  margin: 0 auto;
}
.image-gallery-icon {
  color: #a9a9a9 !important;
}
.image-gallery-icon:hover {
  color: $rmd-theme-secondary !important;
}
//.image-gallery-bullets {
//  bottom: -10px !important;
//}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0 !important;
  transform: translateY(-100%) !important;
}
.image-gallery-right-nav {
  right: 20px;
}
.image-gallery-left-nav {
  left: 20px;
}
//.image-gallery-bullets .image-gallery-bullet {
//  border: 1px solid #8c8c8c !important;
//  box-shadow: 1px 2px 5px 1px #bfbfbf;
//}
//.image-gallery-bullets .image-gallery-bullet:hover {
//  background: $rmd-theme-secondary !important;
//}
//.image-gallery-bullets .image-gallery-bullet.active {
//  background: $rmd-theme-primary !important;
//}
.image-gallery-swipe {
  display: none;
}
.image-gallery-thumbnails {
  background: black !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 4px solid $rmd-theme-secondary !important;
}
.image-gallery-thumbnail:hover {
  border: 4px solid #b31100 !important;
}
.image-gallery-index {
  padding: 6px 18px !important;
  right: 74px !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 100px;
  width: 60px;
}
.trash {
  opacity: 0;
  transition: opacity ease-in-out 300ms;
}
.thumbnail-custom-container:hover {
  cursor: pointer;
  .trash {
    opacity: 1;
  }
}
