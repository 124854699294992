.appbar-title {
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0;
  img {
    width: 28px;
    margin: auto 6px;
  }
}

.top-icon {
  width: 36px;
  height: 36px;
  color: white;
}

.out-button {
  color: white;
  border: 2px solid white;
  box-shadow: none !important;
  font-size: 12px;
  height: 34px;
  min-height: 34px;
  margin: 0 4px;
  padding: 0 5px;
}
.select-app {
  width: 122px;
  svg {
    margin: 0;
  }
}
.dropdown-item {
  max-width: 120px;
  font-weight: bold;
  color: #1a303e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.appbar-action {
  svg {
    circle {
      stroke: white;
    }
  }
}

.dropdown-content {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

//.logo-text-large {
//}
//.logo-text-small {
//  display: none;
//}

.online-mode-dropdown {
  pointer-events: none;
  justify-content: center;
}

@media (max-width: 424px) {
  //.logo-text-large {
  //  display: none;
  //}
  //.logo-text-small {
  //  display: flex;
  //  font-size: 26px;
  //}
  .select-app {
    width: 48px;
  }
  .dropdown-content {
    text-overflow: clip;
  }
}
