.camera-params {
  width: 600px;
  position: relative;
  height: calc(100% - 30px);
}

.camera-preview {
  height: calc(100% - 30px);
  flex-grow: 1;
  max-width: calc(100% - 600px);
}

.slider-page {
  background: transparent !important;
}

.camera-card-config {
  width: 80%;
  height: 90%;
}

.camera-card-preview {
  width: calc(100% - 30px);
  height: 90%;
  overflow: hidden;
}

.camera-preview-content {
  height: calc(100% - 100px);
}

.camera-card-button {
  width: 32%;
  margin: 0 0 6px;
  height: 42px;
}

.camera-card-text-field {
  margin-bottom: 12px;
}

.camera-dropdown-menu {
  margin: 0;
  width: 130px;
}

.capabilities-span {
  font-size: 18px;
  color: #223f53;
  font-weight: bold;
}

#camConfigCapabilitiesContainer {
  //height: calc(100% - 180px);
  padding-top: 14px;
}

.camera-preview-loading {
  svg {
    circle {
      stroke: #223f53;
    }
  }
}

.camera-preview-dropdown-button {
  font-weight: bold;
  text-transform: unset;
  height: 36px;
  width: 130px;
}

.camera-params-fields {
  height: calc(100% - 80px);
  overflow: auto;
  padding: 12px 0;
}

.camera-config-loading {
  height: 42px;
  svg {
    height: 24px;
    width: 24px;
  }
}

.system-cam-capabilities {
  overflow: auto;
}

.small-card-content {
  height: calc(100% - 70px);
}

#dropdownCameras-menu {
  max-height: 60vh;
}

.advanced-camera-container {
  border: 1px solid #d6d5d7;
  border-top: 0 solid #d6d5d7;
  border-radius: 3px;
}

.advanced-camera-title {
  border-top: 1px solid #d6d5d7;
  border-bottom: 1px solid #d6d5d7;
  margin-bottom: 16px;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  color: #223f54;
  cursor: pointer;
  &:hover {
    background-color: rgba(34, 63, 84, 0.075);
  }
  i {
    color: #223f54;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.advanced-camera-properties {
  padding: 6px 12px;
  height: calc(100% - 57px);
}

#systemCamConfigContent {
  height: fit-content;
}

@media (max-width: 1024px) {
  .camera-preview {
    max-width: 100%;
  }
  .camera-config {
    align-content: space-around;
    flex-wrap: wrap;
  }
  .camera-card-preview {
    height: calc(100% - 10px);
    width: 100%;
  }
  .small-preview {
    height: 40%;
    width: 80%;
    flex-grow: unset;
    margin: 0 auto;
  }
  .small-params {
    height: calc(60% - 14px);
    width: 100%;
  }
  .small-card-container-params {
    align-items: flex-start !important;
  }
  .small-card-title {
    padding: 0 10px !important;
    height: 40px !important;
    font-size: 20px !important;
  }
  .small-capabilities {
    display: none !important;
  }
  .camera-preview-content {
    padding: 0 !important;
    height: calc(100% - 40px);
  }
  .small-card-content {
    padding: 0 18px 8px !important;
    height: calc(100% - 40px);
  }
  .camera-preview-dropdown-button {
    width: 100px !important;
    margin: 0 !important;
  }
  .camera-params-fields {
    height: calc(100% - 48px);
    overflow: auto;
    padding-top: 20px;
  }
  .system-cam-capabilities {
    overflow: unset;
  }
  .camera-card-button {
    height: 36px !important;
  }
  .camera-card-text-field {
    height: 36px !important;
    font-size: 14px !important;
    margin-bottom: 14px;
  }
  .camera-config-zoom-80 {
    zoom: 81%;
  }
  #camConfigCapabilitiesContainer {
    padding-top: 4px;
    .input-range {
      margin: 24px 0 44px 0;
    }
  }
  #camera-config-general-params-card {
    .rmd-text-field-container--outline .rmd-floating-label {
      top: -12px;
    }
    .rmd-text-field-container--outline .rmd-floating-label--active {
      top: -2px;
    }
  }
}

@media (max-width: 520px) {
  .small-card-params {
    width: calc(100% - 12px) !important;
  }
  .small-preview {
    width: 100%;
  }
  .camera-card-preview {
    width: calc(100% - 12px);
  }
}
