@import '../../../themeVariables';

$iconContainerHeight: 60px;
$cardTitleHeight: 40px;
$cardFooterMinHeight: 70px;

$bigColMinHeight: 100px;
$smallColMinHeight: 80px;

.group-card {
  position: relative;
  width: 80%;
  height: calc(100% - 40px);
  margin-bottom: 40px;
}

.group-card-icon-container {
  width: 100%;
  height: $iconContainerHeight;
  background-color: #223f54;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.group-card-title {
  height: $cardTitleHeight;
  padding: 4px 20px 14px;
  background-color: #223f54;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.group-info {
  font-weight: 600;
  margin-bottom: 12px;
}

.group-property {
  color: #223f54;
}

.group-value {
  color: #717171;
}

.group-footer {
  background-color: $rmd-theme-primary;
  color: $rmd-theme-on-primary;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: $cardFooterMinHeight;
  div {
    width: calc(50% - 2px);
  }
}

.group-owner-title {
  margin: 8px 0 0 0;
}

.group-owner-value {
  padding: 12px 18px;
  text-align: center;
  &:hover {
    overflow-wrap: anywhere;
    text-overflow: unset;
    white-space: normal;
    height: fit-content;
  }
}

.license-awesome-slider-container {
  width: 100%;
  height: 90%;
  position: relative;
}

.groups-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  width: 100%;
  padding: 0 !important;
  background-color: #dadde2;
  display: grid;
  grid-gap: 10px;
  min-width: 100%;
}

.license-rows-container {
  background-color: #223f541a;
}

.license-row {
  display: flex;
  padding: 0 12px;
  align-items: center;
  height: 60px;
  color: #223f54;
  font-weight: 600;
  min-width: 100%;
  border-radius: 3px;
  &:hover {
    overflow-wrap: anywhere;
    text-overflow: unset;
    white-space: normal;
    height: fit-content;
    min-height: 60px;
    background-color: #9aa8b5ba;
    transition: background-color 300ms ease-in-out;
  }
}

.license-col {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 4px 8px;
  height: 100%;
  div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    max-height: 100%;
    width: 100%;
  }
}

.col1 {
  width: 20%;
  border-width: 0 3px 0 0;
  border-color: #dadde2;
  border-style: solid;
  min-width: $bigColMinHeight;
}

.col2 {
  width: 10%;
  border-width: 0 3px 0 0;
  border-color: #dadde2;
  border-style: solid;
  min-width: $smallColMinHeight;
}

.col3 {
  width: 10%;
  border-width: 0 3px 0 0;
  border-color: #dadde2;
  border-style: solid;
  min-width: $smallColMinHeight;
}

.col4 {
  width: 20%;
  border-width: 0 3px 0 0;
  border-color: #dadde2;
  border-style: solid;
  min-width: $bigColMinHeight;
}

.col5 {
  width: 20%;
  min-width: $bigColMinHeight;
}

.col6 {
  width: 10%;
}

.col7 {
  width: 10%;
}

.visibility-icon {
  color: $rmd-theme-primary !important;
  padding: 12px;
}

.license-dots-container {
  display: flex;
  overflow: hidden;
  height: 8px;
}

.license-dots {
  border-radius: 50%;
  background-color: $rmd-theme-primary;
  height: 7px;
  width: 7px !important;
  margin: 0 1px;
}

.license-row-separator {
  width: 100%;
  height: 6px;
  background-color: #dadde2;
}

.license-rows-header {
  height: 46px;
  //padding: 0 12px;
  background-color: $rmd-theme-primary;
  color: $rmd-theme-on-primary;
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  top: 0;
  min-width: 100%;
  div {
    border: none;
    min-height: unset;
  }
}

.magic-container {
  max-height: calc(100% - #{$iconContainerHeight} - #{$cardTitleHeight} - #{$cardFooterMinHeight});
  position: relative;
  overflow-x: auto;
  padding-top: 52px;
  display: grid;
  flex-grow: 1;
}

.license-footer-divider {
  width: 2px;
  height: 64%;
  background-color: #dadde2;
  border: none;
  margin: auto;
  border-radius: 10px;
}

.new-license-button {
  height: 32px;
  min-height: unset;
  font-size: 14px !important;
}

.remove-license-icon {
  font-size: 28px;
  color: red;
  padding: 12px;
}

.no-group-message-container {
  width: 310px;
  max-width: 70%;
  height: 450px;
  max-height: 90%;
  //border: 6px solid #223f54;
}

.no-group-title {
  text-align: center !important;
  overflow: hidden;
  font-size: 24px !important;
  margin-top: 10px;
}

.no-group-message-card-content {
  height: calc(100% - 150px);
  overflow: auto;
}

.no-group-separator {
  border: none;
  height: 3px;
  width: 60%;
  background-color: #223f5447;
  margin: 22px auto;
}
.no-group-message-text {
  font-size: 17px;
  color: #757575;
  margin-bottom: 12px;
  padding: 0 16px;
  font-weight: 600;
  text-align: center;
}

.no-group-message-footer {
  background-color: $rmd-theme-primary;
  color: $rmd-theme-on-primary;
  font-size: 18px;
  font-weight: 600;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 12px 0;
  div {
    margin-bottom: 12px;
  }
  a {
    text-decoration: underline;
    color: white;
  }
}

.my-calendar-container {
  position: relative;
  width: 100%;
}

.visible-calendar-label {
  position: absolute;
  top: -7px;
  left: 14px;
  padding: 0 0.25rem;
  font: 12px Roboto, sans-serif;
  background-color: #f2f4f6;
  transition: display 500ms;
}

.hidden-calendar-label {
  display: none;
}

.gray-label {
  color: #696969;
}

.blue-label {
  color: #2196f3;
}

.react-datepicker-wrapper {
  width: 100% !important;
  height: 44px !important;
  border: 1px solid #d6d7d9 !important;
  border-radius: 0.25rem !important;
  div:first-child {
    height: 100% !important;
    border-radius: 0.25rem !important;
    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      padding: 0 16px !important;
      border-radius: 0.25rem !important;
      transition: box-shadow 100ms;
      font: 16px Roboto, sans-serif;
      color: #696969;
      &::placeholder {
        color: #696969;
        font: 16px Roboto, sans-serif;
      }
      &:hover:not(:focus-visible) {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.87);
      }
      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px #2196f3;
      }
    }
  }
}
