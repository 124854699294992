.quality-container {
  width: 50%;
  padding: 8px;
  min-width: 300px;
}

.tabs-wrapper {
  overflow: hidden;
}
#qualityTabsManager {
  height: 44px;
  display: inline-flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  button {
    height: 100%;
    border: none;
    padding: 0;
    width: 150px;
    min-width: 150px;
    color: #223f54;
  }
}

.custom-tab-button {
  background-color: #bcece8;
  cursor: pointer;
  padding: 0 8px;
  font-weight: bold;
  font-size: 14px;
}

.change-tab-button {
  height: 44px;
  width: 70px;
  font-size: 26px !important;
  color: #e3f2f1 !important;
  background: #223f54;
}

#qualityTabPanels {
  display: flex;
  height: calc(100% - 88px);
  width: 100%;
}

.tab-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 16px;
}
