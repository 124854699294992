.local-container {
  padding: 20px;
}
.local-cards-container {
  place-content: space-evenly flex-start;
  height: fit-content;
  width: fit-content;
}
.local-inner-card {
  width: 340px;
  height: 575px;
  display: flex !important;
  flex-wrap: wrap;
  background-color: #f3f5f7 !important;
  align-content: flex-start;
  margin: 10px;
  padding: 12px !important;
}
.local-inner-card-title {
  padding: 10px;
  width: 100%;
  height: fit-content;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #223f54;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.local-inner-card-content {
  width: 100%;
  padding: 0 !important;
  height: 180px;
  div {
    padding: 0;
  }
}
.local-title-and-image-container {
  width: 100%;
  height: calc(100% - 180px);
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.local-app-image-container {
  width: 100%;
  height: calc(100% - 45px);
  margin: 0 auto;
  place-content: center;
  background: black;
  border-radius: 6px;
}

.appConfig-list {
  max-height: 250px;
}

.local-card-footer {
  height: 104px;
  align-items: flex-end;
}

.button-row {
  height: fit-content;
}

.local-button {
  margin: 4px 0;
  width: 49%;
  background: #cccccc;
  padding: 0 10px !important;
}

.appConfig-label {
  display: flex;
}
#edgeLoginPanel-container {
  margin: 12px 0;
}
#edgeLoginPanel {
  .rmd-expansion-panel__icon {
    display: none;
  }
}

.deploy-card {
  width: 350px;
  min-height: 400px;
}
@media (max-width: 767px) {
  .local-container {
    padding: 6px;
  }

  .local-app-image-container {
    max-height: calc(100% - 45px);
    height: unset;
  }

  .local-cards-container {
    place-content: flex-start;
    width: 100% !important;
  }
  .local-inner-card {
    width: 100%;
    flex-wrap: unset;
    height: 250px;
    align-items: center;
    flex-direction: row;
    min-width: unset !important;
    max-height: unset !important;
    margin: 4px 0;
    justify-content: space-between;
  }
  .local-inner-card-title {
    font-size: 16px !important;
    text-align: center;
  }
  .local-inner-card-content {
    width: 48%;
    height: 100%;
    align-content: space-between;
  }

  .local-title-and-image-container {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: 100%;
    width: 48%;
  }
}
