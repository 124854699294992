.menu-avatar-container {
  padding: 24px 0 0;
}
.user-icon {
  font-size: 100px;
}
.menu-item {
  font-weight: 600;
  color: white;
  i {
    color: white;
  }
}
.menu-item-selected {
  //background-color: #223f54;
  background-color: #0dad9f;
}
.menu-item:hover {
  background-color: rgba(34, 63, 84, 0.4);
  //background-color: rgba(13, 173, 159, 0.5);
}
