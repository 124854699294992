.roi {
  background: transparent;
  border: 2px dashed red;
}
.roi-grid {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 15% 70% 15%;
  grid-template-rows: 1fr 3fr 1fr;
  justify-content: center;
}
.list-button {
  border-radius: 0;
}
