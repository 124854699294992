.detail-buttons-container {
  margin: 20px 0 0 0;
}

.detail-thumb-container {
  background-color: black;
  height: calc(100% - 106px);
}

.detail-thumb {
  object-fit: contain;
}

.detail-button {
  width: 49%;
}
.detail-select {
  width: 49%;
  height: 42px !important;
}
