.output-button {
  border-radius: 0 !important;
  flex-grow: 1;
}

.tab-content-wrapper {
  width: 630px;
  height: 400px;
}
.selected-tab {
  border-bottom: 4px solid #223f54 !important;
  box-shadow: none !important;
}
.inactive-output-button {
  background-image: linear-gradient(170deg, #c5c5c5, #a7bcbffa) !important;
  box-shadow: none !important;
}

.fields {
  margin: 0;
  width: 100%;
}

.error-field {
  border-color: #b31100 !important;
  border-width: 2px !important;
  color: #500900;
  --rmd-form-text-active-color: #b31100 !important;
}

.error-info {
  color: rgb(83, 9, 0);
  margin: 4px 6px 12px;
}
.hidden-error {
  margin: 12px 0 0 0;
  width: 100%;
}

.lineContainer {
  width: calc(50% - 6px);
}
