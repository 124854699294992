.bottom-container {
  margin: 30px 0 12px 0;
  .rmd-text-field-container {
    width: 54%;
  }
}
.appClass-capture-section-button {
  width: 49%;
}
.change-class-button {
  font-size: 22px !important;
  width: 20%;
  svg {
    height: 26px;
    width: 26px;
  }
}
.predict-container {
  margin: 18px 0 0 0;
}

.capture-button-container {
  margin: 18px 0 0 0;
}

.predicted-title {
  margin: 0 0 4px 0;
  color: dimgrey;
  font-size: 19px;
  font-weight: bold;
}

.predicted-class {
  font-weight: bold;
  height: 42px;
  font-size: 18px;
  border-radius: 4px;
  background: #bcece8;
  color: #223f53;
  width: 65%;
  box-shadow: 2px 8px 14px -12px rgba(0, 0, 0, 0.75);
}
