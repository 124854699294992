a {
  text-decoration: none;
}
.webgl-card {
  width: 60%;
  height: 70%;
}
.webgl-card-content {
  height: calc(100% - 70px);
  overflow: auto;
}
.webgl-text-content {
  min-height: 100px;
  width: 80%;
  height: 70%;
  font-size: 18px;
  font-weight: 600;
  color: #223f53;
  margin: 0 auto;
  text-align: center;
  overflow: auto;
  background: #223f5312;
  border-radius: 0.3rem;
  padding: 12px;
  div {
    margin: 4px 0;
  }
}

.webgl-image-content {
  height: 30%;
  padding: 6px 0 0 0;
}

@media (max-width: 767px) {
  .webgl-card {
    width: 90%;
    height: 90%;
  }
  .webgl-text-content {
    width: 90%;
  }
}
